import React from 'react'
import * as styles from './Label.module.css'

export interface LabelProps {
  elmFor?: string,
  label?: string
}

export const Label: React.FC<LabelProps> = ({
  elmFor = 'name',
  label = 'Name'
}) => {
  return (<label className={styles.label} htmlFor={ elmFor }>{ label }</label>)
}
import * as React from 'react'
import { useState } from 'react'
import Layout from '../components/layout/Layout'
import { Button } from '../components/button/Button'
import { Label } from '../components/label/Label'
import { TextField } from '../components/textField/TextField'
import { header, container, h1, field } from '../styles/contact.module.css'


const ContactPage = () => {
  const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: ''
  })

  const handleChange = (e: any) => {
    console.log(e)
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const encode = (data: any) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...formData,
      }),
    })
    .then(() => console.log('thanks'))
    .catch((error) => alert(error));
  };

  return (
    <Layout>
      <header className={header}>
        <div className={container}>
          <h1 className={h1}>Contact</h1>
        </div>
      </header>
      <div className={container}>
        <form method='post' netlify-honeypot='bot-field' data-netlify='true' name='contact' onSubmit={handleSubmit}>
          <input type='hidden' name='bot-field' />
          <input type='hidden' name='form-name' value='contact' />
          <div className={field}>
            <Label elmFor={'name'} label={'Name'} />
            <TextField type={'name'} name={'name'} textarea={false} onChange={handleChange} value={formData.name} />
          </div>
          <div className={field}>
            <Label elmFor={'email'} label={'Email'} />
            <TextField type={'email'} name={'email'} textarea={false} onChange={handleChange} value={formData.email} />
          </div>
          <div className={field}>
            <Label elmFor={'message'} label={'Message'} />
            <TextField type={'message'} name={'message'} textarea={true} onChange={handleChange} value={formData.message} />
          </div>
          <div className={field}>
            <Button type='submit' primary={false} label={'Submit'} />
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default ContactPage
import React from 'react'
import * as styles from './TextField.module.css'

export interface TextFieldProps {
    type: string
    name: string
    rows?: number
    cols?: number
    value?: string
    placeholder?: string 
    textarea?: boolean
    onChange: (val: React.ChangeEvent<HTMLTextAreaElement>| React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextField: React.FC<TextFieldProps> = ({
    type = '',
    name = '',
    placeholder = '',
    value = '',
    textarea = false,
    onChange
}) => {
    if(textarea) {
      return (<textarea className={styles.textarea} name={ name } rows={4} cols={50} placeholder={ placeholder } value={ value } onChange={(event) => onChange(event)}></textarea>)
    } else {
      return (<input className={styles.input} type={ type } name={ name } placeholder={ placeholder } value={ value } onChange={(event) => onChange(event)} />)
    }
}
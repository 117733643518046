import React from 'react'
import * as styles from './Button.module.css'

export interface ButtonProps {
  primary?: boolean;
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large';
  type?: 'submit' | 'button';
  label: string;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  primary = false,
  size = 'medium',
  type = 'button',
  backgroundColor,
  label,
  ...props
}) => {
  const mode = primary ? 'primary' : 'secondary'
  const stylesSize = styles[size]
  const stylesMode = styles[mode]

  return (
    <button
      type={type}
      className={ `${styles.button} ${stylesSize} ${stylesMode}` }
      style={{ backgroundColor }}
      {...props}
    >
      {label}
    </button>
  );
};
  